import React, { useEffect } from "react";
import logo from "../assets/img/logos/EE24_SIGNATURE_RGB_positive_DE.png";

import "../scss/information.scss";

const Information = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="information " id="info">
        <div>
          <h2>EU on tour – Die Wanderausstellung zur Europawahl</h2>
        </div>{" "}
        <div className="container-lg">
          <p>
            Am <strong>9. Juni 2024</strong> findet die <strong>Europawahl in Deutschland</strong> statt! Aber wer wird dabei eigentlich gewählt und wie kannst
            du <strong>deine Stimme</strong> abgeben? In dieser Ausstellung des Europäischen Parlaments erhältst du alle wichtigen Infos zur Wahl, lernst die
            Aufgaben des Europäischen Parlaments kennen und erfährst, warum <strong>deine Stimme</strong> zählt!
          </p>
        </div>
      </div>
    </>
  );
};
export default Information;
