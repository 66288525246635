import React, { useEffect } from "react";
import "../scss/about.scss";

const Imprint = () => {
  return (
    <div className="frame-container frame-container-default">
      <div className="frame-inner">
        <div className="frame-header">
          <h1 className="element-header  ">
            <span>Impressum</span>
          </h1>
        </div>

        <p>Angaben gemäß § 5 TMG:</p>
        <p>
          <strong>Valentum Kommunikation GmbH</strong>
          <br /> Bischof-von-Henle-Str. 2b
          <br /> 93051 Regensburg
        </p>
        <p>
          <strong>Vertreten durch:</strong>
          <br /> Anke Gruber
          <br /> Frank Zeller
        </p>
        <p>
          <strong>Kontakt:</strong>
          <br /> T&nbsp; 0941 591896 10
          <br /> F&nbsp; 0941 591896 11
        </p>
        <p>
          <a href="#" data-mailto-token="ocknvq,kphqBxcngpvwo/mqoowpkmcvkqp0fg" data-mailto-vector="2">
            info@valentum-kommunikation.de
          </a>
          <br />{" "}
          <a href="https://www.valentum-kommunikation.de" target="_blank" rel="noreferrer noopener">
            www.valentum-kommunikation.de
          </a>
        </p>
        <p>
          <strong>Registereintrag:</strong>
          <br /> Registergericht: Regensburg
          <br /> Registernummer: HRB 11277
        </p>
        <p>
          <strong>Umsatzsteuer-ID:</strong>
          <br /> Umsatzsteuer-Identifikationsnummer gemäß §27&nbsp;a Umsatzsteuergesetz:
          <br /> DE 263156672
        </p>
        <p>
          <strong>Inhaltlich Verantwortliche:</strong>
          <br /> Anke Gruber
          <br /> Frank Zeller
        </p>
      </div>
      <a href="/">Zurück</a>
    </div>
  );
};
export default Imprint;
