import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import flag from "../assets/img/logos/Flag.png";
import img1 from "../assets/img/posters/1.jpg";
import img2 from "../assets/img/posters/2.jpg";
import img3 from "../assets/img/posters/3.jpg";
import img4 from "../assets/img/posters/4.jpg";
import img5 from "../assets/img/posters/5.jpg";
import img6 from "../assets/img/posters/6.jpg";
import img7 from "../assets/img/posters/7.jpg";
import img8jpg from "../assets/img/posters/8.jpg";

import pdfa1 from "../assets/pdf/EP_Wanderausstellung_Plakate_A1.pdf";
import pdfa3 from "../assets/pdf/EP_Wanderausstellung_Plakate_A3.pdf";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../scss/poster.scss";

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import EventManager from "../js/EventManager";
const Poster = () => {
  useEffect(() => {}, []);
  const [posterOpen, setPosterOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(Number);
  const [position, setPosition] = React.useState<"top" | "bottom" | "start" | "end">("bottom");
  const [width, setWidth] = React.useState(120);
  const [height, setHeight] = React.useState(80);
  const [border, setBorder] = React.useState(1);
  const [borderRadius, setBorderRadius] = React.useState(4);
  const [padding, setPadding] = React.useState(4);
  const [gap, setGap] = React.useState(16);
  const [preload, setPreload] = React.useState(2);
  const [showToggle, setShowToggle] = React.useState(false);
  const handleOpenLightbox = (index: number) => {
    setActiveIndex(index);
    setPosterOpen(true);
  };

  const slidesArr = [{ src: img1 }, { src: img2 }, { src: img3 }, { src: img4 }, { src: img5 }, { src: img6 }, { src: img7 }, { src: img8jpg }];
  EventManager.subscribe("ausstellungClicked", handleOpenLightbox);

  return (
    <>
      <Lightbox
        open={posterOpen}
        close={() => setPosterOpen(false)}
        index={activeIndex}
        slides={slidesArr}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        carousel={{ finite: true, preload: 10, padding: 0, imageFit: "contain" }}
        thumbnails={{
          position: "bottom",
          width: 41,
          height: 124,
          border: 0,
          borderRadius: 4,
          padding: 0,
          gap: 16,
          showToggle: false,
        }}
      />

      <Container fluid id="ausstellung">
        <Row className="gx-0 row flex-wrap">
          <Col key="1" className="custom-col" onClick={() => handleOpenLightbox(0)}>
            <img src={img1} />
          </Col>
          <Col key="2" className="custom-col" onClick={() => handleOpenLightbox(1)}>
            <img src={img2} />
          </Col>
          <Col key="3" className="custom-col" onClick={() => handleOpenLightbox(2)}>
            <img src={img3} />
          </Col>
          <Col key="4" className="custom-col" onClick={() => handleOpenLightbox(3)}>
            <img src={img4} />
          </Col>
          <Col key="5" className="custom-col" onClick={() => handleOpenLightbox(4)}>
            <img src={img5} />
          </Col>
          <Col key="6" className="custom-col" onClick={() => handleOpenLightbox(5)}>
            <img src={img6} />
          </Col>
          <Col key="7" className="custom-col" onClick={() => handleOpenLightbox(6)}>
            <img src={img7} />
          </Col>
          <Col key="8" className="custom-col" onClick={() => handleOpenLightbox(7)}>
            <img src={img8jpg} />
          </Col>
        </Row>
        <p className="mt-5 mb-0">Du kannst dir die Ausstellung auch als Plakatversion zum Ausdrucken herunterladen.</p>
        <Row className="d-flex p-2 bd-highlight justify-content-md-center justify-content-center mb-5">
          <Col></Col>
          <Col></Col>
          <Col></Col>
          <Col className=" text-center">
            <Button target="_blank" href={pdfa3} className="btn-lg">
              Plakate DIN-A3
            </Button>
          </Col>
          <Col className="text-center">
            <Button href={pdfa1} target="_blank" className="btn-lg">
              Plakate DIN-A1
            </Button>
          </Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};
export default Poster;
