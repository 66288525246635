import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { Navbar, Nav, Container } from "react-bootstrap";
import logo_ep from "../assets/img/logos/EP_Logo_EP.jpg";

import "../scss/footer.scss";

const Footer = () => {
  useEffect(() => {
    document.title = "EU on Tour";
  }, []);
  return (
    <>
      <Navbar expand="lg" className="">
        <Container>
          <Navbar.Brand className="col-md-6" href="#home">
            <img src={logo_ep} alt="Die Flagge der Europäischen Union." /> Ein Angebot des Europäischen Parlaments
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="col-md-6 offset-md-6">
              <Link to="/imprint">Impressum</Link>
              <Link to="/data-privacy">Datenschutz</Link>
              {/* Add more Nav.Link or other components as needed */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Footer;
